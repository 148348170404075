import React, { useEffect } from 'react';
import { LayoutUserExperience } from '../components/layouts/layout-user-experience';
import { Container, Row, Col } from 'react-bootstrap';
import { PrimaryButton } from '../components/buttons/primary-button';
import downTrend from "../images/down-trend-arrow.svg";
import upTrend from "../images/up-trend-arrow.svg";
import lightGlobe from "../images/light-globe.svg";
import heart from "../images/heart.svg";
import wordMouth from "../images/speech-bubble.svg";
import resources from "../images/resources.svg";
import persona from "../images/persona.svg";
import userJourney from "../images/user-journey.svg";
import ethnographic from "../images/ethnographic.svg";
import cardSorting from "../images/card-sorting.svg";
import startDay from "../images/start-your-day.svg";
import showTell from "../images/show-tell.svg";
import productTree from "../images/prune-product-tree.svg";
import meShadow from "../images/me-shadow.svg";
import productBox from "../images/product-box.svg";
import apprentice from "../images/apprentice.svg";
import speedboat from "../images/speedboat.svg";
import buyFeature from "../images/buy-feature.svg";
import twentyTwenty from "../images/20-20.svg";
import spiderWeb from "../images/spider-web.svg";
import hotTub from "../images/hot-tub.svg";
import rememberFuture from "../images/remember-future.svg";
import uxBenefitsHero from "../images/ux-benefits-hero.png";
import badUXchair from "../images/bad-ux-chair.png";
import badUXfork from "../images/bad-ux-fork.png";
import badUXtoothbrush from "../images/bad-ux-toothbrush.png";
import badUXwatercan from "../images/bad-ux-watercan.png";
import facebookPoster from "../images/facebook-poster.png";
import surferVideo from "../video/surf-destinations-light.mp4";
import natureVideo from "../video/nature-light.mp4";
import closeBtn from "../images/close-btn.svg";
import { Helmet } from "react-helmet";
import Swiper from 'react-id-swiper';
import SEO from '../components/shared/seo';
import { LogoText } from '../components/shared/logo';
import {
  IoIosTrendingUp,
  IoMdLaptop,
  IoIosCode,
  IoIosCloud,
  IoIosDoneAll,
} from 'react-icons/io';

function uxSolutionsModal() {
  var element = document.getElementById("enable-ux-solutions");
  element.classList.add("active");
  document.body.classList.add("noScroll");
}

function cancelBtn() {
  var element = document.getElementById("enable-ux-solutions");
  element.classList.remove("active");
  document.body.classList.remove("noScroll");
}


function uxGamesModal() {
  var element = document.getElementById("enable-ux-workshops");
  element.classList.add("active");
  document.body.classList.add("noScroll");
}


function cancelBtn2() {
  var element = document.getElementById("enable-ux-workshops");
  element.classList.remove("active");
  document.body.classList.remove("noScroll");
}




function priceList() {
  var element = document.getElementById("ux-start");
  element.classList.add("active");
  document.body.classList.add("noScroll");

  dataLayer.push({
    event: 'GaModal',
    EventCategory: 'uxModal',
    EventAction: 'open',
    EventLabel: 'Getting Started with UX'
  })
}



function cancelBtn3() {
  var element = document.getElementById("ux-start");
  element.classList.remove("active");
  document.body.classList.remove("noScroll");
}







const params1 = {
  parallax: true,
  centeredSlides: true,
  loop: true,
  // autoplay: {
  // delay: 5000,
  // disableOnInteraction: true,
  // },
  pagination: {
    el: '.swiper-pagination1',
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + (index + 1) + '</span>';
    },
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  speed: 3000,
}



const params2 = {
  spaceBetween: 30,
  centeredSlides: true,
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },
  loop: true,
  pagination: {
    el: '.swiper-pagination2',
    clickable: true,
  },
  speed: 3000,
}

const params3 = {
  slidesPerView: 'auto',
  spaceBetween: 30,
  freeMode: true,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },

}









function UserExperience() {
  return (
    <>
      <Helmet>
        <meta name="robots" content="index, follow" />
        <meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
        <meta name="bingbot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
        <meta property="og:title"content="Data backed UX Design helps you get fast ROI and increases your conversion rates" />
        <meta property="og:url" content="https://www.diviv.com.au/user-experience" />
        <meta property="og:description" content="Diviv UX Design is a data backed way to improve Lead Generation and Conversions for your website or web application.  Fast ROI ✔ | Satisfaction Guaranteed ✔" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={facebookPoster} /> 
      </Helmet>
      <SEO 
        title="UX Design Perth | UI UX Design Specialist| Diviv Group Perth" 
        description=" Diviv UX Design is a data backed way to improve Lead Generation and Conversions for your website or web application.  Fast ROI ✔ | Satisfaction Guaranteed ✔"
        keywords="Perth UI UX, User Experience Design, UX Design Perth, Website Design Perth, User Experience Design Perth, UX Strategy"
      />
      <LayoutUserExperience>
        <div
          id="user-experience-content"
          className="page-layout"
          data-menu-class="inverted"
        >

          <section className="hero">
            <div className="swiper-container swiper1">
              <Swiper {...params1}>
                <div className="swiper-slide">
                  <div className="inner-wrapper">
                    <div className="title" data-swiper-parallax="-700">UX STRATEGY</div>
                    <div className="text" data-swiper-parallax="-100" data-swiper-parallax-duration="600">
                      <p>Great UX design makes an app easy and pleasant to use and as a result, increases customer satisfaction which improves your bottom line. </p>
                    </div>
                    <div className="call-to-action">





                      <PrimaryButton
                        className="btn btn-primary"
                        onClick={priceList}
                      >
                        GET STARTED
                              </PrimaryButton>


                      <PrimaryButton
                        to="/contactus"
                        className="btn btn-primary"
                      >
                        ENQUIRE NOW
                              </PrimaryButton>



                    </div>



                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="inner-wrapper">
                    <div className="title" data-swiper-parallax="-700">REMOTE WORKSHOPS</div>
                    <div className="text" data-swiper-parallax="-100" data-swiper-parallax-duration="600">
                      <p>We provide innovative solutions to facilitate all workshops online. </p>
                    </div>



                    <div className="call-to-action">


                      <PrimaryButton
                        to="/contactus"
                        className="btn btn-primary"
                      >
                        ENQUIRE NOW
                              </PrimaryButton>






                    </div>
                  </div>
                </div>

              </Swiper>

            </div>

          </section>

          <section className="ux-intro">
            <Container>
              <Row>
                <Col className="ux-intro__wrapper ">

                  <div className="ux-intro__animated-graphic top">

                    <video playsInline autoPlay loop muted width="100%">
                      <source src={surferVideo} type="video/mp4" />
                    </video>

                  </div>

                  <div className="ux-intro__description top">

                    <div className="flex-inner-container">
                      <h5>What is UX?</h5>

                      <p>
                        UX stands for ‘User experience’ and is the design process to create products that are
                        meaningful and relevant for users. Essentially, a user experience is a person’s
                        emotions and attitude towards using a product, system or service. It’s about how a user feels when using it.
                      </p>


                    </div>
                  </div>

                </Col>

                <Col className="ux-intro__wrapper">

                  <div className="ux-intro__description bottom">


                    <div className="flex-inner-container">
                      <h5>What is UI?</h5>

                      <p>
                        UI stands for ‘user interface’ and is the graphical layout of an application. This includes all
                        visual elements, transitions, animations and micro-interactions. All of which must be designed.
                      </p>


                    </div>


                  </div>

                  <div className="ux-intro__animated-graphic bottom">
                    <video playsInline autoPlay loop muted width="100%">
                      <source src={natureVideo} type="video/mp4" />
                    </video>
                  </div>


                </Col>


              </Row>


            </Container>
          </section>

          <section className="ux-quote">
            <Container>
              <Row>
                <Col md={12}>
                  <div className="diviv-banner">
                    <div className="diviv-banner__logo">
                      <LogoText />
                    </div>
                    <p className="diviv-banner__text">
                      “At Diviv, we believe in creating exceptional experiences that enrich the live of our users. And our entire team works with passion and diligence to ensure that happens, from the first meeting all the way through to a production release and beyond.”
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>







          <section className="ux-bad">
            <Container>
              <Row>
                <Col md={12} lg={5}>

                  <div className="ux-bad__slide-wrapper">


                    <div className="badux-header">
                      <p>Images courtesy <strong>Katrina Kamprani</strong> </p> <p className="small"> Discover more at <a href="http://www.theuncomfortable.com/" target="_blank" >www.theuncomfortable.com</a> </p>
                    </div>

                    <div className="swiper-container swiper2">
                      <Swiper {...params2}>
                        <div className="swiper-slide"><img src={badUXchair} alt="Bad UX chair" /></div>
                        <div className="swiper-slide"><img src={badUXfork} alt="Bad UX fork" /></div>
                        <div className="swiper-slide"><img src={badUXtoothbrush} alt="Bad UX toothbrush" /></div>
                        <div className="swiper-slide"><img src={badUXwatercan} alt="Bad UX watercan" /></div>
                      </Swiper>
                      <div className="swiper-pagination swiper-pagination2"></div>
                    </div>
                    <div className="badux-footer">
                      <p>Enjoy some fun illustrations which emphasise bad UX. These items may be aesthetically beautiful (UI) and somewhat creative, but from a practical sense, they would frustrate users, resulting in a bad user experience.</p>
                    </div>



                  </div>





                </Col>
                <Col md={12} lg={7}>

                  <div className="flex-inner-container">
                    <h3>What is bad UX?</h3>

                    <ul>

                      <li>Unable to complete tasks</li>
                      <li>Page not optimally designed for different screen sizes</li>
                      <li>Not accessible for users with disabilities</li>
                      <li>Too many options that overwhelm users</li>
                      <li>Layout is too condensed and  hard to read</li>
                      <li>No clear direction to do anything</li>


                      <li>CTA’s are not clear</li>
                      <li>Things are not where YOU think they should be</li>
                      <li>Video starts playing automatically</li>
                      <li>Ad’s pop up and takes you to another page</li>
                      <li>Terrible search. You can’t find what you’re looking for</li>
                      <li>Page takes too long to load</li>




                    </ul>


                  </div>
                </Col>
              </Row>
            </Container>
          </section>


          <section className="ux-solutions">
            <Container>
              <Row>
                <Col md={12}>

                  <div className="description-container-centered">

                    <h3>UX solutions</h3>
                    <p>Let’s take a look at the benefits of good UX design</p>
                  </div>

                  <div className="block-container">

                    <div className="block">

                      <div className="icon"><img src={downTrend} alt="Downtrend" /></div>

                      <div className="ux-solutions__title"> UX design cuts down development costs</div>

                    </div>
                    <div className="block">

                      <div className="icon"><img src={upTrend} alt="Uptrend" /></div>

                      <div className="ux-solutions__title">UX design increases your revenue</div>

                    </div>
                    <div className="block">

                      <div className="icon">
                        <img src={lightGlobe} alt="Motivates" />
                      </div>
                      <div className="ux-solutions__title">UX design motivates your users </div>

                    </div>
                    <div className="block">

                      <div className="icon">  <img src={heart} alt="Loyalty" /></div>
                      <div className="ux-solutions__title">UX design increases customer loyalty</div>

                    </div>
                    <div className="block">
                      <div className="icon"><img src={wordMouth} alt="Word of mouth" /> </div>

                      <div className="ux-solutions__title">UX design stimulates word-of-mouth</div>

                    </div>
                    <div className="block">
                      <div className="icon"><img src={resources} alt="Resources" /></div>



                      <div className="ux-solutions__title">UX design keeps you from wasting resources</div>
                    </div>

                  </div>



                  <div className="ux-solutions__button-wrapper">

                    <button className="btn btn-primary" onClick={uxSolutionsModal} >Learn more</button>

                  </div>

                </Col>
              </Row>
            </Container>
          </section>


          <section className="ux-measures">
            <Container>
              <Row>
                <Col md={12}>

                  <div className="description-container-centered">

                    <h3>Measuring UX design</h3>
                    <p>UX KPIs translate the success factors of your project, department or company into numbers, bringing successes and failures to light.</p>
                  </div>



                  <div className="block-container">
                    <div className="row">
                      <div className="block-half stakeholder">

                        <h6>Measure UX</h6>
                        <h5>Stakeholder management</h5>

                        <p>KPIs help you communicate your UX issues and the associated strategic goals more successfully to the relevant decision makers in your company.</p>


                      </div>
                      <div className="block-half benchmarking">

                        <h6>Measure UX</h6>
                        <h5>UX benchmarking</h5>

                        <p>Just like planning a journey, you should first find out exactly where you are located before attempting to navigate to a destination.</p>




                      </div>
                    </div>

                    <div className="block-full measure">

                      <h6>Measure UX</h6>
                      <h3>What should be measured?</h3>

                      <div className="kpi-wrapper">
                        <div className="kpi">
                          <h5>Behavioural UX KPIs</h5>

                          <div className="block">



                            <ul>
                              <li>Task success rate</li>
                              <li>Time-on-task</li>
                              <li>Search v’s navigation</li>
                              <li>User error rate</li>
                              <li>Engagement</li>
                              <li>Adoption</li>
                              <li>Retention</li>
                            </ul>


                          </div>
                          <h5>Attitudinal UX KPIs</h5>
                          <div className="block">

                            <ul>
                              <li>System Usability Scale (SUS)</li>
                              <li>Net Promoter Score (NPS)</li>
                              <li>Customer Satisfaction (CSAT)</li>
                            </ul>

                          </div>

                        </div>
                        <div className="ux-banner">
                          <div className="ux-banner__inside">
                            What <br />
                        gets <br /><span> measured</span> <br /> gets <br />
                        managed!
                              </div>
                        </div>
                      </div>





                    </div>

                  </div>



                </Col>
              </Row>
            </Container>
          </section>


          <section className="ux-workshops">
            <Container>
              <Row>
                <Col md={12}>

                  <div className="description-container-centered">

                    <h3>Let’s get started?</h3>
                    <p>We offer a series of workshops and innovation games to help better understand customers,
                      and in doing so solve a variety of problems associated with creating innovative products and services.</p>
                  </div>



                  <div className="block-container">

                    <div className="block">

                      <div className="icon"><img src={persona} alt="Persona" /></div>

                      <div className="ux-workshops__title"> Customer persona workshop</div>

                      <p>Personas are a tool to characterise your target users to make better product decisions. A workshop is a great way to encourage your stakeholders to think about user needs effectively instead of thinking about solutions first.</p>

                    </div>
                    <div className="block">

                      <div className="icon"><img src={userJourney} alt="User Journey" /></div>
                      <div className="ux-workshops__title">Empathy User Journey mapping</div>

                      <p>Empathy mapping is a way to characterise your target users in order to make effective design decisions. User journey mapping is a way to deconstruct a user’s experience with a product or service as a series of steps.</p>


                    </div>
                    <div className="block">

                      <div className="icon">
                        <img src={ethnographic} alt="Ethnographic" />
                      </div>
                      <div className="ux-workshops__title">Ethnographic research </div>
                      <p>Ethnographic research is a qualitative method where researchers observe and/or interact with a study’s participants in their real-life environment.</p>

                    </div>
                    <div className="block">

                      <div className="icon">  <img src={cardSorting} alt="Card sorting" /></div>
                      <div className="ux-workshops__title">Card sorting workshop</div>

                      <p>Card sorting gives you insight into how people conceptualise, group and label ideas, enabling you to make confident, informed information architecture decisions.</p>

                    </div>

                  </div>

                  <div className="ux-solutions__button-wrapper">

                    <button className="btn btn-primary" onClick={uxGamesModal} >Learn more</button>

                  </div>

                </Col>
              </Row>
            </Container>
          </section>




          <section className="innovation-games-outer">


            <div className="description-container-centered">
              <h3>Innovations Games</h3>
              <p>Creating breakthrough products through collaborative play</p>
            </div>



            <div className="swiper-container swiper3">
              <Swiper {...params3}>
                <div className="swiper-slide">
                  <div className="icon-wrapper">
                    <div className="icon">
                      <img src={showTell} alt="Show and tell" />
                    </div>
                  </div>
                  <div className="ux-innovations-games__title">Show and tell </div>
                  <p>Your customers describe the most important artefacts produced by your system to you and other customers.</p>
                </div>

                <div className="swiper-slide">
                  <div className="icon-wrapper">
                    <div className="icon">
                      <img src={startDay} alt="Start your day" />
                    </div>
                  </div>
                  <div className="ux-innovations-games__title">Start your day </div>
                  <p>Customers collaboratively describe when, how and where they use your product.</p>
                </div>






                <div className="swiper-slide">
                  <div className="icon-wrapper">
                    <div className="icon">
                      <img src={productTree} alt="Product Tree" />

                    </div>
                  </div>
                  <div className="ux-innovations-games__title">Prune your prduct tree</div>
                  <p>Customers work in small teams to shape the evolution of your products and services.</p>
                </div>


                <div className="swiper-slide">
                  <div className="icon-wrapper">
                    <div className="icon">
                      <img src={meShadow} alt="Me and my shadow" />

                    </div>
                  </div>
                  <div className="ux-innovations-games__title">Me and my shadow</div>
                  <p>Discover hidden needs by carefully observing what customers actually do with your product.</p>
                </div>




                <div className="swiper-slide">
                  <div className="icon-wrapper">
                    <div className="icon">
                      <img src={productBox} alt="Product Box" />
                    </div>
                  </div>
                  <div className="ux-innovations-games__title">Product box</div>
                  <p>Customers work individually or in small teams to create and sell their ideal product.</p>
                </div>



                <div className="swiper-slide">
                  <div className="icon-wrapper">
                    <div className="icon">
                      <img src={apprentice} alt="Apprentice" />
                    </div>
                  </div>
                  <div className="ux-innovations-games__title">The apprentice</div>
                  <p>Create empathy for the customer experience by doing the job of a customer.</p>

                </div>



                <div className="swiper-slide">
                  <div className="icon-wrapper">
                    <div className="icon">
                      <img src={speedboat} alt="Speedboat" />
                    </div>
                  </div>
                  <div className="ux-innovations-games__title">Speed boat</div>
                  <p>Customers identify their biggest pain points with your products and services.</p>
                </div>



                <div className="swiper-slide">
                  <div className="icon-wrapper">
                    <div className="icon">
                      <img src={buyFeature} alt="Buy a feature" />

                    </div>
                  </div>
                  <div className="ux-innovations-games__title">Buy a feature</div>
                  <p>Customers work together to purchase their most desired features.</p>
                </div>




                <div className="swiper-slide">
                  <div className="icon-wrapper">
                    <div className="icon">
                      <img src={twentyTwenty} alt="Twenty Twenty" />
                    </div>
                  </div>
                  <div className="ux-innovations-games__title">20/20 vision</div>
                  <p>Customers negotiate the relative importance of such things as product features, market requirements and product benefits.</p>
                </div>



                <div className="swiper-slide">
                  <div className="icon-wrapper">
                    <div className="icon">
                      <img src={spiderWeb} alt="Spider web" />
                    </div>
                  </div>
                  <div className="ux-innovations-games__title">Spider web</div>
                  <p>Customers work individually or in small  teams to create vivid pictures of how your products and services fit into their world.</p>

                </div>



                <div className="swiper-slide">
                  <div className="icon-wrapper">
                    <div className="icon">
                      <img src={hotTub} alt="Give them a hot tub" />
                    </div>
                  </div>
                  <div className="ux-innovations-games__title">Give them a hot tub</div>
                  <p>Customers provide feedback on the outrageous features to establish what is truely essential.</p>

                </div>




                <div className="swiper-slide">
                  <div className="icon-wrapper">
                    <div className="icon">
                      <img src={rememberFuture} alt="Remember future" />
                    </div>
                  </div>
                  <div className="ux-innovations-games__title">Remember the future</div>
                  <p>Understand your customers definition of success by seeing how they shape their future.</p>
                </div>
              </Swiper>
              <div className="swiper-pagination swiper-pagination3"></div>
            </div>



          </section>
        </div>



        <div id="enable-ux-solutions" className="diviv-modal outer ">
          <div className="diviv-modal__inner color-block">
            <div className="diviv-modal-content" >


              <Container>

                <header className="diviv-modal-header-background"> <img src={uxBenefitsHero} alt="UX benefits hero" /></header>
                <Row>
                  <Col md={12} lg={5} className="headerContent">

                    <button className="backBtn" onClick={cancelBtn}>Back</button>
                    <div className="headerContent__inner">
                      <h6>UX Solutions</h6>
                      <h4>The benefits of good UX design</h4>
                    </div>
                  </Col>
                  <Col md={7}></Col>
                </Row>


                <section >
                  <Row>
                    <Col md={12} lg={5} className="bodyContent"> <h5>UX design cuts down development costs</h5> </Col>
                    <Col md={12} lg={7} className="bodyContent right">
                      <ul>
                        <li>Developers spend 50% of their time fixing issues which could have been avoided.</li>
                        <li>Fixing a problem in development costs 10 times as much as fixing it in design, and 100 times as much if you’re trying to fix the problem in a product that’s already been released.</li>
                        <li>Prototyping allows development teams to estimate with greater accuracy.</li>
                        <li>UX design prevents the adding of unnecessary features that result in failed deadlines and blown budgets.</li>
                        <li>Usability testing ensures users are going to use and be satisfied with your product well before writing code.</li>
                        <li>UX design mitigates risk. By effective prototyping, stakeholders get to see what it looks like, see how it works and users get to evaluate to know if it's going to meet their needs.</li>
                      </ul>
                    </Col>
                  </Row>
                </section>



                <section >
                  <Row>
                    <Col md={12} lg={5} className="bodyContent"> <h5>UX design increases your revenue</h5> </Col>
                    <Col md={12} lg={7} className="bodyContent right">
                      <p>Whether it’s to read an article on your website, signup to a subscription, or purchase an item, there are many design techniques to help befriend users with your content.</p>
                      <p>Did you know?</p>
                      <ul>
                        <li>Research shows that, on average, every $1 invested in UX brings $100 in return. That’s an ROI of a whopping 9,900%.</li>
                        <li>The top companies leading in user experience outperformed the S&amp;P index by 35%.</li>
                        <li>A well-designed user interface could raise your website’s conversion rate by up to a 200%, and a better UX design could yield conversion rates up to 400%. </li>
                        <li>A major eCommerce company increased sales by $300 million after changing their button text from “register” to “continue”.</li>
                        <li>23% of customers who had a positive experience told 10 or more people about it. </li>
                      </ul>

                    </Col>
                  </Row>
                </section>



                <section >
                  <Row>
                    <Col md={12} lg={5} className="bodyContent"> <h5>Understanding Motivation</h5> </Col>
                    <Col md={12} lg={7} className="bodyContent right">
                      <p>Three elements have to be present in order for a specific behaviour to occur: motivation, ability, and triggers. For example, if you want to increase the opportunity to see something, the user must be:</p>
                      <ul>
                        <li><strong>Motivated:</strong> Do they have a need for the item you’re selling? Will it be of value to them?</li>
                        <li><strong>Able:</strong> Can they order the item? Can it only be purchased in another country? If so, users won’t be able to purchase.</li>
                        <li><strong>Triggered:</strong> Have you triggered them to perform the behaviour? Do you have a “Buy Now” button? Are you reminding them to purchase an item or provide suggestions on other related products?</li>
                      </ul>

                    </Col>
                  </Row>
                </section>




                <section >
                  <Row>
                    <Col md={12} lg={5} className="bodyContent"> <h5>UX design increases customer loyalty</h5> </Col>
                    <Col md={12} lg={7} className="bodyContent right">
                      <p>Great UX design not only attracts new users, but also retains them. Returning users are loyal users. Customer loyalty is won through positive experiences with a web or mobile app. These positive experiences bind users to your product and keep them coming back for more.</p>


                    </Col>
                  </Row>
                </section>



                <section >
                  <Row>
                    <Col md={12} lg={5} className="bodyContent"> <h5>UX design stimulates word-of-mouth</h5> </Col>
                    <Col md={12} lg={7} className="bodyContent right">
                      <p>Word-of-mouth is the sharing of information about a product or service through personal communications, on social media, and in day-to-day interactions. When people spread the word about your product just because they want to and not because you’ve paid them, then your product is being advertised by word-of-mouth.
More contact means more sharing of information, gossiping, exchanging, engaging – in short, more word-of-mouth by:</p>
                      <ul>
                        <li> <strong>Facilitates social sharing</strong> - One example of this recognition is the displaying of links to social media sites targeted at specific audiences. </li>
                        <li><strong>Promotes trust</strong> - People tend to believe others who are like them and tend to learn behaviours from their peers. UX solutions such as reviews, star ratings, and “people who bought this item also bought” features build customers’ sense of trust.</li>

                      </ul>
                    </Col>
                  </Row>
                </section>


                <section >
                  <Row>
                    <Col md={12} lg={5} className="bodyContent"> <h5>UX design keeps you from wasting resources</h5> </Col>
                    <Col md={12} lg={7} className="bodyContent right">
                      <p> By investing in better UX design solutions, you’re able to avoid wasting human resources, time, and money. Human resources are all the people involved in creating your product. Unfortunately, when employees aren’t managed well, the result can be financial losses. </p>

                      <p>Spending time conducting user experience research helps meet the needs of your target audience which goes a long way to increasing customer satisfaction </p>
                      <p>At the prototyping stage stakeholders not only get to see what it looks like, they get to experience how it works all without writing one line of code. Marketers can also evaluate their content to know if it's going to motivate users to complete tasks. Prototypes are also tested with real users to ensure user problems have been solved which mitigates risk.</p>

                      <p>By having a good UX design strategy shows Dev teams what it is they’re shooting for. This allows them to estimate more accurately how long and avoids any rework by building something that has already been prototyped and tested.
                      </p>

                    </Col>
                  </Row>
                </section>
              </Container>
            </div>
          </div>
        </div>




        <div id="enable-ux-workshops" className="diviv-modal outer ">
          <div className="diviv-modal__inner color-block">
            <div className="diviv-modal-content" >



              <Container>

                <header className="diviv-modal-header-background"> <img src={uxBenefitsHero} alt="UX benefits hero" /></header>
                <Row>
                  <Col md={12} lg={6} className="headerContent">

                    <button className="backBtn" onClick={cancelBtn2}>Back</button>

                    <div className="headerContent__inner">
                      <h6>Workshops</h6>
                      <h4>workshops &amp; innovation games</h4>
                      <p>To setup a consultation to discuss options directly related to your product, please submit an enquiry.</p>
                    </div>
                  </Col>
                  <Col md={12} lg={6}></Col>
                </Row>

                <section >

                  <Col md={12} className="bodyContent right"> <h4>What is a UX workshop and how will it benefit me?</h4> </Col>

                  <Row className="bodyContentWrapper">
                    <Col md={12} lg={6} className="bodyContent" >
                      <p className="emphasis">UX workshops are intensive collaborative sessions used to solve problems and enable progress on a particular challenge throughout the design timeline.</p>
                      <p>Workshops enable participants to come together for a concentrated time of idea generation and hands-on activities that allow them to achieve an actionable goal.</p>
                      <p>Workshops provide a solution-based approach to solving problems. They are extremely useful in tackling complex problems that are ill-defined or unknown, by understanding the human needs involved. The result of workshop will establish a clear idea of exactly which problems need solving.</p>

                    </Col>

                    <Col md={12} lg={6} className="bodyContent" >
                      <p>The following questions can be answered through workshops and innovation games:</p>
                      <ul>
                        <li>What features do customers want in the next release?</li>
                        <li>How do customers use your product?</li>
                        <li>How do your customers perceive the relationship between your product and other similar products?</li>
                        <li>What is your customers definition of success?</li>
                      </ul>



                    </Col>
                  </Row>

                </section>



              </Container>
            </div>
          </div>
        </div>



        <div id="ux-start">

          <div className="price__container" >
            <div onClick={cancelBtn3} className="closeBtn">

              <img src={closeBtn} alt="Close" />

            </div>

            <div className="title"><h3>Getting started with UX</h3></div>


            <div className="tiles">

              <div className="tiles__item">

                <div className="tiles__category">
                  Getting started
                </div>
                <div className="tiles__level">
                  Basic
                </div>


                <div className="tiles__features">

                  <ul>

                    <li>UX, UI consultant experienced based usability study</li>
                    <li>Website analytics review</li>

                  </ul>

                  <div className="tiles__cta"><a href="/contactus">3 day review</a></div>

                </div>

              </div>


              <div className="tiles__item advanced">

                <div className="tiles__category">
                  Evidence based design
                </div>
                <div className="tiles__level">
                  Advanced
                </div>


                <div className="tiles__features">

                  <ul>
                    <li>Conduct stakeholder interviews</li>
                    <li>Website analytics and feedback from user groups</li>
                    <li>UX, UI consultant based usability study.</li>


                  </ul>

                  <div className="tiles__cta"><a href="/contactus">7 day review</a></div>

                </div>





              </div>
              <div className="tiles__item">
                <div className="tiles__category">
                  User centric focus
                </div>
                <div className="tiles__level">
                  Premium
                </div>


                <div className="tiles__features">

                  <ul>
                    <li>Stakeholder interviews</li>
                    <li>Analytics research</li>
                    <li>Ethnographic research</li>
                    <li>UX, UI usability study</li>
                    <li>Measure UX KPI’s every 2 months</li>

                  </ul>

                  <div className="tiles__cta"><a href="/contactus">14 day review</a></div>

                </div>
              </div>

            </div>



          </div>
        </div>



      </LayoutUserExperience>
    </>
  );
}

export default UserExperience;


import React from "react";
import { Footer } from "../global/footer";

interface Props {}

export const LayoutUserExperience: React.FC<Props> = props => {
  return (
    <div className="layout--user-experience">
      {props.children}
      <Footer />
    </div>
  );
};
